import React, { useState, useEffect, useCallback } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserFromEmail, getEmailFromId } from "../functions/getUser";
import CheckoutPage from "./CheckoutPage";
import axios from "axios";
import { Card, Spinner } from "react-bootstrap";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import ComponentCard from "./ComponentCard";

const APIkey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(APIkey);

export default function CreateSubscription(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const subscription = {
    user_id: "",
    product_id: "",
  };
  const firebaseAddress = process.env.REACT_APP_SERVER_URL;

  useEffect( () => {
    async function checkPaidStatus() {
      const userData = (await getUserFromEmail(props.user.email)).userData;
      if (userData.paid === true) {
        navigate("/new");
      } else {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("class_id");
        if (!id) {
          setFeedback({
            variant: "warning",
            message: "You have no items to check out. Redirecting...",
            loading: true,
          });
          setTimeout(() => {
            navigate("/show-service-class");
          }, 2000);
          return;
        }
        if (props.user && !subscribed) {
          subscribe(props.user, id);
        }
      }
    }
    checkPaidStatus()
  }, [subscribed, props.user, location, navigate]);

  const subscribe = useCallback(async (user, id) => {
    const subscription = {
      user_id: user.id,
      product_id: id,
    };
    await axios
      .post(`${firebaseAddress}createSubscription`, {
        subscription,
      })
      .then((res) => {
        setClientSecret(res.data.data.clientSecret);
        setSubscribed(true);
      })
      .catch((err) => {
        console.log("error while creating ", err);
      });
  }, []);

  useEffect(() => {
    console.log("hi world");
  }, []);

  useEffect(() => {
    console.log("hi world user");
  }, [props.user]);

  const options = {
    clientSecret,
    appearance: {
      // Check Stripe Appearance for more customizations
      theme: "stripe",
      rules: {
        ".Tab": {
          border: "1px solid #E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(255, 255, 255, 0.02)",
        },

        ".Tab:hover": {
          color: "var(--colorText)",
        },

        ".Tab--selected": {
          borderColor: "#E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
        },

        ".Input--invalid": {
          boxShadow:
            "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
        },
      },
    },
  };

  if (clientSecret === "") {
    return (
      <ComponentCard title="Checkout">
        <Spinner size="sm" /> Loading...
      </ComponentCard>
    );
  }

  return (
    <Card
      body
      className="pb-0"
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      <h4 className="mt-3">
        <b>Checkout</b>
      </h4>
      <hr />
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutPage clientSecret={clientSecret} />
        </Elements>
      )}
      <FeedbackAlert feedback={feedback} className="mb-0" />
    </Card>
  );
}
